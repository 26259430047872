import React from "react";
import useAppContext from "@hooks/useAppContext";
import ImageCDN from "@components/skeleton/ImageCDN";
import { ImageDefault } from "@components/utils/Utils";
import dynamic from "next/dynamic";
import GradientCover from "@components/common/GradientCover";
import {
  IQueryItemActivityType,
  IQueryItemExperience,
  IQueryItemLocationType,
} from "@customTypes/filtersProvider";

const CheckMark = dynamic(
  async () => await import("@components/icons/CheckMark")
);

type IItemType =
  | IQueryItemExperience
  | IQueryItemLocationType
  | IQueryItemActivityType;

interface IFilterModalCard {
  item: IItemType;
  onClickHandler: (item: IItemType) => void;
}

const FilterModalCard = ({
  item,
  onClickHandler,
}: IFilterModalCard): JSX.Element => {
  const { isMobile } = useAppContext();
  const image = item.Media?.url;

  return (
    <div
      className={`relative w-full ${
        isMobile ? "h-[176px]" : "h-[220px]"
      } cursor-pointer`}
      onClick={() => onClickHandler(item)}
    >
      <div
        className={`relative rounded-xl ${
          item.isChecked ? "ring-[2px] ring-orange" : ""
        }  w-full ${isMobile ? "h-[176px]" : "h-[220px]"} overflow-hidden`}
      >
        <GradientCover rounded={true} opacity={80} />
        <ImageCDN
          className="rounded-xl"
          src={ImageDefault(image)}
          alt="w"
          height={isMobile ? "176" : "220"}
          width={isMobile ? "132" : "156"}
          layout="fill"
          objectFit="cover"
        />
        <p className="absolute bottom-1 z-[2] flex w-full justify-center px-[10px] pb-[10px] text-center text-[14px] font-[400] leading-[22px] text-white">
          {item.title}
        </p>
        {item.isChecked && (
          <div
            className={`absolute top-2 left-2 flex h-5 w-5 items-center rounded-md bg-orange ring-[2px] ring-white`}
          >
            <CheckMark color="white" />
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterModalCard;
