import { useState, useEffect, useMemo } from "react";
import useScreenType from "./useScreenType";

interface IUseDetectDeviceReturn {
  isMobile: boolean;
  isPrerender: boolean;
  isRealMobileDevice: boolean;
}

const useDetectDevice = (): IUseDetectDeviceReturn => {
  const [isPrerender, setIsPrerender] = useState(false);
  const screenType = useScreenType();
  const isMobile = useMemo(() => {
    return screenType.type === "mobile";
  }, [screenType]);
  const isRealMobileDevice = useMemo(() => {
    return screenType.variant === "real";
  }, [screenType]);

  useEffect(() => {
    setIsPrerender(navigator.userAgent.toLowerCase().includes("prerender"));
  }, []);

  return { isMobile, isPrerender, isRealMobileDevice };
};

export default useDetectDevice;
