import React, { useMemo } from "react";

interface IShowMoreButton {
  handleButtonClick: () => void;
  canShowMore?: boolean;
  variant?: string;
}

const ShowMoreButton = ({
  handleButtonClick,
  canShowMore,
  variant,
}: IShowMoreButton): JSX.Element => {
  const buttonText = useMemo(() => {
    switch (variant) {
      case "moreOptions":
        return (
          <span className="pr-2 text-p14">
            {canShowMore ? "More" : "Less"} Options
          </span>
        );
      default:
        return (
          <span className="pr-2 text-p14 font-semibold">
            Show {canShowMore ? "More" : "Less"}
          </span>
        );
    }
  }, [variant, canShowMore]);

  return (
    <button
      onClick={handleButtonClick}
      className="flex w-full border-b-2 border-dashed border-orange-20 text-[16px] text-orange hover:border-orange "
    >
      <div className="flex items-center">
        {buttonText}
        <i
          style={{
            border: "solid",
            borderWidth: "0 3px 3px 0",
            display: "inline-block",
            padding: "3px",
            transform: `${canShowMore ? "rotate(45deg)" : "rotate(225deg)"}`,
            marginTop: `${canShowMore ? "-5px" : "0px"}`,
          }}
        ></i>
      </div>
    </button>
  );
};

export default ShowMoreButton;
