import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
  Context,
} from "react";
import { FiltersContext, IFiltersContext } from "providers/FiltersProvider";
import dateRangePickerConfigNamu from "../utils/dateRangePickerConfigNamu";
// @ts-expect-error
import { START_DATE, END_DATE } from "react-dates/constants";
import "react-dates/initialize";
import moment, { Moment } from "moment";
import dynamic from "next/dynamic";
import { IUseCalendarReturn } from "@hooks/useCalendar";
import { FocusedInputShape } from "react-dates";

const DayPickerRangeController = dynamic(
  async () =>
    await import("react-dates/lib/components/DayPickerRangeController")
);

interface ISearchCalendar
  extends Pick<
    IUseCalendarReturn,
    | "setStartDate"
    | "setFocusedInput"
    | "setCalendarStatus"
    | "focusedInput"
    | "monthsToShow"
    | "setMonthsToShow"
    | "startDateState"
    | "endDateState"
    | "setEndDate"
  > {
  isMobilePopUp?: boolean;
  observe?: string;
}

const CALENDAR_BLOCK_HEIGHT = 240; // The height of one block of the calendar (including days and month name)

const SearchCalendar = ({
  setCalendarStatus,
  focusedInput,
  setFocusedInput,
  monthsToShow,
  setMonthsToShow,
  startDateState,
  setStartDate,
  endDateState,
  setEndDate,
  isMobilePopUp,
  observe,
}: ISearchCalendar): JSX.Element => {
  const calendarRef = useRef<HTMLDivElement>(null);
  const { changeCalendarSelection } = useContext<IFiltersContext>(
    FiltersContext as Context<IFiltersContext>
  );
  function onDatesUpdate(dates: {
    startDate: Moment | null;
    endDate: Moment | null;
  }): void {
    setFocusedInput(START_DATE);
    if (!focusedInput) {
      setStartDate(null);
      setEndDate(null);
    }
    if (focusedInput === START_DATE) {
      setStartDate(dates.startDate);
      setEndDate(null);
    }
    if (focusedInput === END_DATE) {
      setEndDate(dates.endDate);
      setFocusedInput(START_DATE);
    }

    if (dates.startDate && dates.endDate) {
      /**
       * https://mentormate.atlassian.net/browse/NAM-431
       * https://mentormate.atlassian.net/browse/NAM-70?focusedCommentId=167049
       * https://mentormate.atlassian.net/browse/NAM-70?focusedCommentId=166353
       */
      const sDate = dates.startDate.format("MMM DD");
      const eDate = dates.endDate.format("MMM DD");

      const eDateDay = dates.endDate.format("DD");

      const sYear = dates.startDate.format("YYYY");
      const year = dates.endDate.format("YYYY");
      const nYear = sYear !== year ? sYear : false;

      const sameMonth =
        dates.startDate.format("MMM") === dates.endDate.format("MMM");
      let textField =
        sDate + (nYear ? ", " + nYear : "") + " - " + eDate + ", " + year;

      if (sameMonth) {
        textField = sDate + " - " + eDateDay + ", " + year;
      }
      setCalendarStatus(textField as unknown as boolean);
      changeCalendarSelection(true);
    }
  }

  const onFocusChange = (focusedInput: FocusedInputShape | null): void =>
    setFocusedInput(focusedInput);

  useEffect(() => {
    if (endDateState && !focusedInput) {
      setCalendarStatus(false);
      setFocusedInput(START_DATE);
    }
  }, [endDateState, focusedInput]);

  useEffect(() => {
    if (endDateState) {
      const currentDate = moment(new Date());
      let monthsCounter = endDateState.diff(currentDate, "months", false) + 2;
      if (endDateState.date() <= currentDate.date()) {
        monthsCounter++;
      }
      setMonthsToShow(monthsCounter);
    } else {
      setMonthsToShow(4);
    }
  }, []);

  useEffect(() => {
    // 2 milisecond delay added to ensure calendar children are fully rendered before scroll
    if (calendarRef.current) {
      setTimeout(() => {
        const selectedEndDate = document.querySelector(
          '[class*="selected_end"]'
        );
        selectedEndDate?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 400);
    }
  }, [calendarRef.current]);

  // Infinitive scroll logic
  const [container, setContainer] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setContainer(document.getElementById(observe || "scroll-container"));
  }, []);

  const handleScroll = useCallback(() => {
    if (
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      container &&
      container?.clientHeight + CALENDAR_BLOCK_HEIGHT + container?.scrollTop >
        container?.scrollHeight
    ) {
      setMonthsToShow((prevState) => prevState + 2);
    }
  }, [container]);

  useEffect(() => {
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [container]);
  // End of infinitive scroll logic

  return (
    <div
      className={`mobile-calendar relative bg-blue-5 ${
        isMobilePopUp ? "" : "overflow-hidden"
      }`}
      ref={calendarRef}
    >
      <DayPickerRangeController
        {...dateRangePickerConfigNamu}
        monthFormat={"MMMM, YYYY"}
        orientation="vertical"
        startDate={startDateState}
        endDate={endDateState}
        onDatesChange={onDatesUpdate}
        focusedInput={focusedInput}
        onFocusChange={onFocusChange}
        numberOfMonths={monthsToShow}
        initialVisibleMonth={() => moment(new Date())}
        navPrev={<></>}
        navNext={<></>}
      />
    </div>
  );
};

export default SearchCalendar;
