import React from "react";
import parse, {
  attributesToProps,
  DOMNode,
  domToReact,
  HTMLReactParserOptions,
} from "html-react-parser";
import { Attributes } from "html-react-parser/lib/attributes-to-props";
import { checkHrefDomain, checkPhoneLink } from "@libs/parser/utils/helpers";
import getConfig from "next/config";
import buildUrl from "cloudinary-build-url";
import { IMAGE_QUALITY } from "@components/utils/constants";
import Image from "next/image";
import { customLoader } from "@components/skeleton/ImageCDN";
import useAppContext from "@hooks/useAppContext";
import FindTripButton from "@components/common/FindTripButton";

interface ReactParserOptions extends Omit<HTMLReactParserOptions, "replace"> {
  replace: ({
    children,
    attribs,
    name,
    parent,
  }: {
    children: DOMNode[];
    attribs: Attributes;
    name: string;
    parent: any;
  }) => JSX.Element | undefined;
}

interface SanitizeProps {
  style?: {
    color?: string;
    fontFamily?: string;
    fontSize?: string;
    lineHeight?: string;
  };
  className?: string;
}

const sanitizeStyles = (
  props: SanitizeProps,
  children?: DOMNode[],
  parent?: any,
  name?: string
): void => {
  delete props?.style?.color;
  delete props?.style?.fontFamily;
  delete props?.style?.fontSize;
  if (!props.style) {
    props.style = {};
  }
  if (parent?.name === "a" || (parent?.name?.includes("h") && name !== "a")) {
    props.className = "";
  }
  props.style.lineHeight =
    // @ts-expect-error
    children.length === 1 && children[0].data?.trim() === "" ? "0px" : "";
};

const styledParser = (
  content: string | undefined,
  skipParagraphStyling?: boolean
): ReturnType<typeof domToReact> => {
  const options: ReactParserOptions = {
    replace: ({
      children,
      attribs,
      name,
      parent,
    }: {
      children: DOMNode[];
      attribs: Attributes;
      name: string;
      parent: any;
    }): JSX.Element | undefined => {
      const props = attributesToProps(attribs);
      switch (name) {
        case "p":
          if (!attribs) {
            return;
          }
          if (attribs) {
            props.className = skipParagraphStyling
              ? ""
              : `${
                  children?.every(
                    // @ts-expect-error
                    (child) => child.data?.trim() === "" || child.name === "br"
                  )
                    ? ""
                    : "mb-4"
                } text-darkgray desktop:text-p text-p14 font-sans`;
            sanitizeStyles(props as SanitizeProps, children, parent, name);
            props.style = {
              ...props.style,
            };
            return (
              <div {...props}>
                {domToReact(children, options as HTMLReactParserOptions)}
              </div>
            );
          }
          break;
        case "li":
          if (!attribs) {
            return;
          }
          if (attribs) {
            props.className = `${
              children?.every(
                // @ts-expect-error
                (child) => child.data?.trim() === "" || child.name === "br"
              )
                ? ""
                : "mb-4"
            } text-darkgray desktop:text-p text-p14 font-sans`;
            sanitizeStyles(props as SanitizeProps, children, parent, name);
            props.style = {
              ...props.style,
            };
            return (
              <li {...props}>
                {domToReact(children, options as HTMLReactParserOptions)}
              </li>
            );
          }
          break;
        case "h2":
          if (!attribs) {
            return;
          }
          props.className = "desktop:mb-3 mb-2 text-h2 text- font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = {
            ...props.style,
          };
          return (
            <h2 {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </h2>
          );
        case "h3":
          if (!attribs) {
            return;
          }
          props.className = "desktop:mb-3 mb-2 text-h3 font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = {
            ...props.style,
          };
          return (
            <h3 {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </h3>
          );
        case "h4":
          if (!attribs) {
            return;
          }
          props.className = "desktop:mb-3 mb-2 text-h4 font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = {
            ...props.style,
          };
          return (
            <h4 {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </h4>
          );
        case "h5":
          if (!attribs) {
            return;
          }
          props.className = "desktop:mb-3 mb-2 text-h5 font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = {
            ...props.style,
          };
          return (
            <h5 {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </h5>
          );
        case "h6":
          if (!attribs) {
            return;
          }
          props.className = "desktop:mb-3 mb-2 text-h6 font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = {
            ...props.style,
          };
          return (
            <h6 {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </h6>
          );
        case "a":
          if (!attribs) {
            return;
          }
          props.className =
            "text-lightblue hover:text-darkblue underline font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = { ...props.style };
          const isPhoneLink = checkPhoneLink(attribs.href);
          const isInnerLink = checkHrefDomain(attribs.href);
          const aTagTarget = isPhoneLink || isInnerLink ? undefined : "_blank";

          /** NAM-2449 */
          if (
            props.href === undefined &&
            (props.id !== "" || typeof props.id !== "undefined")
          ) {
            props.style = {
              opacity: "0",
              position: "relative",
              top: "-80px",
            };
            props.rel = "noopener";
          }
          const isFindTripLink = props.href?.includes("find-trip");

          return isFindTripLink ? (
            <div className="flex w-full justify-center">
              <FindTripButton className="text-p16 w-auto cursor-pointer font-sans font-semibold text-orange underline hover:text-orange-100">
                {domToReact(children, options as HTMLReactParserOptions)}
              </FindTripButton>
            </div>
          ) : (
            <a {...props} target={aTagTarget}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </a>
          );
        case "strong":
          if (!attribs) {
            return;
          }
          props.className = "font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = { ...props.style };
          return (
            <strong {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </strong>
          );
        case "i":
          if (!attribs) {
            return;
          }
          props.className = "font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = { ...props.style };
          return (
            <i {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </i>
          );
        case "ul":
          if (!attribs) {
            return;
          }
          props.className = "ml-8 mb-4 font-sans";
          if (!props.style) {
            props.style = {};
            props.style.listStyleType = "disc";
          }
          if (props.style && !props.style.listStyleType) {
            props.style.listStyleType = "disc";
          }
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = { ...props.style };
          return (
            <ul {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </ul>
          );
        case "ol":
          if (!attribs) {
            return;
          }
          props.className = "ml-8 mb-4 font-sans";
          if (!props.style) {
            props.style = {};
            props.style.listStyleType = "decimal";
          }
          if (props.style && !props.style.listStyleType) {
            props.style.listStyleType = "decimal";
          }
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = { ...props.style };
          return (
            <ol {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </ol>
          );
        case "blockquote":
          if (!attribs) {
            return;
          }
          props.className = "italic font-sans";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = { ...props.style };
          return (
            <blockquote {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </blockquote>
          );
        case "span":
          if (!attribs) {
            return;
          }
          props.className = `${
            // @ts-expect-error
            children.length === 1 && children[0].data?.trim() === ""
              ? ""
              : "mb-4"
          } text-darkgray desktop:text-p text-p14 font-sans`;
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = { ...props.style };
          return (
            <span {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </span>
          );
        case "figure":
          if (!attribs) {
            return;
          }
          props.className = "mb-4";
          sanitizeStyles(props as SanitizeProps, children, parent, name);
          props.style = { ...props.style };
          return (
            <figure {...props}>
              {domToReact(children, options as HTMLReactParserOptions)}
            </figure>
          );
        case "img":
          if (!attribs) {
            return;
          }

          const isCloudinaryImage = props.src?.includes("cloudinary");

          const { publicRuntimeConfig } = getConfig();
          const { isMobile } = useAppContext();
          let cloudinaryUrl;

          if (isCloudinaryImage) {
            const arr = props.src.split("/");
            const imageName = arr[7];
            cloudinaryUrl = buildUrl(
              `${publicRuntimeConfig.FOLDER_NAME}/${imageName}`,
              {
                cloud: {
                  cloudName: publicRuntimeConfig.CLOUD_NAME,
                },
                transformations: {
                  width: isMobile ? 420 : 900,
                  quality: isMobile
                    ? IMAGE_QUALITY.MOBILE
                    : IMAGE_QUALITY.DESKTOP,
                  effect: "improve",
                  crop: "fill",
                  format: "webp",
                },
              }
            );
          }
          return isCloudinaryImage ? (
            <Image
              {...props}
              loader={customLoader}
              src={cloudinaryUrl as string}
              width={isMobile ? 420 : 900}
              // Obsolete due to height being calculated by cloudinary request, but is required as a prop in next/image so set as 1 as a placeholder value
              height={1}
              alt={props.alt ?? ""}
              loading="lazy"
            />
          ) : (
            <img {...props} loading="lazy" />
          );
      }
    },
  };
  if (content) {
    return parse(content, options as HTMLReactParserOptions);
  }
  return "";
};

export default styledParser;
