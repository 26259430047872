import React, { useState, useContext, useMemo } from "react";
import useAppContext from "@hooks/useAppContext";
import { FiltersContext } from "providers/FiltersProvider";
import ShowMoreButton from "@components/common/ShowMoreButton";
import FilterModalCard from "./filterModalCard";
import { FILTER } from "@components/utils/constants";
import {
  IQueryItemActivityType,
  IQueryItemExperience,
  IQueryItemLocationType,
} from "@customTypes/filtersProvider";

const INITIAL_ROWS = 2;
const IMAGES_PER_ROW = 3;
const IMAGES_AMOUNT = INITIAL_ROWS * IMAGES_PER_ROW;

interface IImagesRows {
  variant: string;
}

const ImagesRows = ({ variant }: IImagesRows): JSX.Element => {
  const { isMobile } = useAppContext();
  const [limitCards, setLimitCards] = useState(true);
  const filtersContext = useContext(FiltersContext);

  const tabType = useMemo(() => {
    switch (variant) {
      case FILTER.STEP.ONE:
        return {
          filterType: FILTER.EXPERIENCE.filterType,
          strapiName: FILTER.EXPERIENCE.strapiName,
          tabName: FILTER.EXPERIENCE.name,
          onClickHandler: (item: IQueryItemExperience) => {
            item.isChecked
              ? filtersContext?.removeExperience()
              : filtersContext?.selectExperience(item);
            if (filtersContext?.globalSearch.value) {
              filtersContext?.setGlobalSearch((prevState) => {
                return {
                  ...prevState,
                  hasSelected: true,
                };
              });
              filtersContext.openFilterModal(1);
            }
          },
        };

      case FILTER.STEP.THREE:
        return {
          filterType: FILTER.LOCATION_TYPE.filterType,
          strapiName: FILTER.LOCATION_TYPE.strapiName,
          tabName: FILTER.LOCATION_TYPE.name,
          onClickHandler: (item: IQueryItemLocationType) => {
            item.isChecked
              ? filtersContext?.removeLocation(item)
              : filtersContext?.selectLocation(item);
          },
        };

      case FILTER.STEP.FIVE:
        return {
          filterType: FILTER.ACTIVITY_TYPE.filterType,
          strapiName: FILTER.ACTIVITY_TYPE.strapiName,
          tabName: FILTER.ACTIVITY_TYPE.name,
          onClickHandler: (item: IQueryItemActivityType) => {
            item.isChecked
              ? filtersContext?.removeActivity(item)
              : filtersContext?.selectActivity(item);
          },
        };

      default:
        // Default is STEP.ONE
        return {
          filterType: FILTER.EXPERIENCE.filterType,
          strapiName: FILTER.EXPERIENCE.strapiName,
          tabName: FILTER.EXPERIENCE.name,
          onClickHandler: (item: IQueryItemExperience) => {
            item.isChecked
              ? filtersContext?.removeExperience()
              : filtersContext?.selectExperience(item);
            if (filtersContext?.globalSearch.value) {
              filtersContext?.setGlobalSearch((prevState) => {
                return {
                  ...prevState,
                  hasSelected: true,
                };
              });
              filtersContext.openFilterModal(1);
            }
          },
        };
    }
  }, [variant]);

  const filterItems = useMemo(() => {
    if (filtersContext?.searchManagement && tabType) {
      const searchManagementArray = filtersContext?.searchManagement[
        tabType.filterType
      ] as Array<{
        id: number;
        tag: IQueryItemExperience;
        location_type: IQueryItemLocationType;
        activity_type: IQueryItemActivityType;
      }>;

      return searchManagementArray
        .map((item) => {
          item[tabType.strapiName].isChecked = filtersContext.searchQuery[
            tabType.tabName
          ]
            .map((searchItem) => searchItem.id)
            .includes(item[tabType.strapiName].id);

          return item;
        })
        .slice(0, limitCards ? IMAGES_AMOUNT : undefined);
    } else {
      return [];
    }
  }, [filtersContext, limitCards]);

  return (
    <div className="flex w-full flex-col items-center pb-8">
      <div
        className={`grid grid-cols-3  ${
          isMobile ? "gap-[0.5rem]" : "gap-[0.75rem]"
        } w-full`}
      >
        {filterItems.map((item) => (
          <FilterModalCard
            key={item.id}
            item={item[tabType.strapiName]}
            // TODO
            // @ts-expect-error
            onClickHandler={tabType?.onClickHandler}
          />
        ))}
      </div>
      {filtersContext?.searchManagement?.[tabType?.filterType] &&
        !(
          !limitCards ||
          IMAGES_AMOUNT >
            filtersContext?.searchManagement?.[tabType?.filterType]?.length - 1
        ) && (
          <div className="mx-auto mt-5">
            <ShowMoreButton
              canShowMore={true}
              handleButtonClick={() => {
                setLimitCards(false);
              }}
            />
          </div>
        )}
    </div>
  );
};
export default ImagesRows;
