import React from "react";

interface IMainWrapper {
  children: JSX.Element | JSX.Element[];
}

const MainWrapper = ({ children }: IMainWrapper): JSX.Element => {
  // Hardcoded pixels are the height of the navigation bar;
  return (
    <main className="overflow-hidden pt-[4.5rem] desktop:overflow-visible desktop:pt-[3.625rem]">
      {children}
    </main>
  );
};

export default MainWrapper;
