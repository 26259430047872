import React from "react";
import StepsBase from "./StepsBase";
import {
  FILTER,
  LOCAL_STORAGE,
  BUDGETS,
  TRAVELERS,
} from "@components/utils/constants";
import {
  ISearchManagement,
  ISearchQuery,
  ISearchManagementTab,
} from "../../types/filtersProvider";

const searchManagementTabsGenerator = (
  searchManagement: ISearchManagement,
  openFilterModal: (tabIndex: number, shouldScroll?: boolean) => void,
  searchQuery: ISearchQuery
): ISearchManagementTab[] => {
  const initialDatesState = JSON.parse(
    // @ts-expect-error
    localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams)
  );

  return [
    {
      index: 0,
      step: 1,
      tabName: FILTER.EXPERIENCE.name,
      shortTitle: searchManagement?.Experiences_Short_Title,
      longTitle: searchManagement?.Experiences_Long_Title,
      amountLikedItems: searchQuery?.[FILTER.EXPERIENCE.name]?.length,
      onExpandHandler: (scroll) => {
        openFilterModal(0, scroll);
      },
      content: <StepsBase variant={FILTER.STEP.ONE} />,
    },
    {
      index: 1,
      step: 2,
      tabName: FILTER.CALENDAR.name,
      shortTitle: searchManagement?.Dates_Short_Title,
      longTitle: searchManagement?.Dates_Long_Title,
      isChecked: !!(initialDatesState?.startDate && initialDatesState?.endDate),
      onExpandHandler: (scroll) => {
        openFilterModal(1, scroll);
      },
      content: <StepsBase variant={FILTER.STEP.TWO} />,
    },
    {
      index: 2,
      step: 3,
      tabName: FILTER.LOCATION_TYPE.name,
      shortTitle: searchManagement?.Locations_Short_Title,
      longTitle: searchManagement?.Locations_Long_Title,
      amountLikedItems: searchQuery?.[FILTER.LOCATION_TYPE.name]?.length,
      onExpandHandler: (scroll) => {
        openFilterModal(2, scroll);
      },
      content: <StepsBase variant={FILTER.STEP.THREE} />,
    },
    {
      index: 3,
      step: 4,
      tabName: "accommodations",
      shortTitle: searchManagement?.Accommodations_Short_Title,
      longTitle: searchManagement?.Accommodations_Long_Title,
      amountLikedItems:
        searchQuery?.accommodations?.hotels?.categories?.length +
        searchQuery?.accommodations?.rentals?.categories?.length +
        (searchQuery?.accommodations?.hotels?.isChecked ? 1 : 0) +
        (searchQuery?.accommodations?.rentals?.isChecked ? 1 : 0) +
        // If one of the budget or rooms amount is equal то default then both will always be displayed hence the 2
        (searchQuery?.accommodations?.budget?.min !== BUDGETS.MIN ||
        searchQuery?.accommodations?.budget?.max !== BUDGETS.MAX ||
        searchQuery?.travelers?.rooms !== TRAVELERS.ROOMS.DEFAULT
          ? 2
          : 0),
      onExpandHandler: (scroll) => {
        openFilterModal(3, scroll);
      },
      content: <StepsBase variant={FILTER.STEP.FOUR} />,
    },
    {
      index: 4,
      step: 5,
      tabName: FILTER.ACTIVITY_TYPE.name,
      shortTitle: searchManagement?.ActivityTypes_Short_Title,
      longTitle: searchManagement?.ActivityTypes_Long_Title,
      amountLikedItems: searchQuery?.[FILTER.ACTIVITY_TYPE.name]?.length,
      onExpandHandler: (scroll) => {
        openFilterModal(4, scroll);
      },
      content: <StepsBase variant={FILTER.STEP.FIVE} />,
    },
  ];
};
export default searchManagementTabsGenerator;
