import "react-loading-skeleton/dist/skeleton.css";
import "../styles/input.css";
import React from "react";
import ErrorBoundary from "../middleware/errorBoundary";
import { SessionProvider } from "providers/SessionProvider";
import { FiltersProvider } from "providers/FiltersProvider";
import { AppUtilsProvider } from "providers/AppUtilsProvider";

import AppContent, { IAppContent } from "@modules/app/AppContent";

export default function MyApp({
  Component,
  pageProps,
}: IAppContent): JSX.Element {
  return (
    <ErrorBoundary>
      <SessionProvider>
        <FiltersProvider pageProps={pageProps}>
          <AppUtilsProvider>
            <AppContent Component={Component} pageProps={pageProps} />
          </AppUtilsProvider>
        </FiltersProvider>
      </SessionProvider>
    </ErrorBoundary>
  );
}
