import React from "react";
import MediaAssets from "@components/utils/MediaAssets";
import dynamic from "next/dynamic";
import Image from "next/legacy/image";
const ExitButton = dynamic(
  async () => await import("@components/icons/ExitButton")
);
const BackButton = dynamic(
  async () => await import("@components/icons/BackButton")
);
interface IModalHeader {
  title: string;
  subtitle?: string;
  hasLogo?: boolean;
  onBackHandler?: () => void;
  onCloseHandler?: () => void;
  isFilterModal?: boolean;
  hasBorder?: boolean;
}

const ModalHeader = ({
  title,
  subtitle,
  hasLogo,
  onBackHandler,
  onCloseHandler,
  isFilterModal,
  hasBorder,
}: IModalHeader): JSX.Element => {
  return (
    <div
      className={`relative flex shrink-0 flex-row items-center overflow-hidden desktop:mx-8 ${
        isFilterModal
          ? "mx-[0.875rem] my-[0.3125rem] desktop:desktop:my-8"
          : "mx-5 mt-6 pb-4"
      } ${hasBorder ? "border-b-[1px] border-b-blue-20" : ""}`}
    >
      {hasLogo && (
        <div className="mr-auto h-fit w-fit">
          <Image
            src={MediaAssets.getAsset("frog_request_quote")}
            width="39"
            height="48"
            layout="fixed"
            alt="Request a quote"
          />
        </div>
      )}
      {onBackHandler && !hasLogo && (
        <div className="mr-auto h-fit w-fit">
          <BackButton onClick={onBackHandler} />
        </div>
      )}
      {onCloseHandler && (
        <div className="ml-auto mr-0 h-fit w-fit">
          <ExitButton onClick={onCloseHandler} />
        </div>
      )}
      <div className="absolute z-[-1] w-full items-center">
        <div className="mx-auto flex w-fit flex-row items-center gap-2">
          <h5 className="text-p font-medium desktop:text-h5">{title}</h5>
          {subtitle && <p className="text-midgray">{subtitle}</p>}
        </div>
      </div>
    </div>
  );
};

export default ModalHeader;
