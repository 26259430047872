import { useContext, useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { FiltersContext } from "providers/FiltersProvider";
import { LOCAL_STORAGE } from "@components/utils/constants";
import dynamic from "next/dynamic";
const PlusButton = dynamic(() => import("@components/icons/PlusButton"));
const MinusButton = dynamic(() => import("@components/icons/MinusButton"));
interface IInputField {
  name: "adults" | "teens" | "children" | "rooms";
  label: string;
  sublabel?: string;

  initialValue: number;
  hasAsterisk?: boolean;
  getValues?: any;
}

const InputCounter = ({
  name,
  label,
  sublabel,
  initialValue,
  hasAsterisk,
}: IInputField): JSX.Element => {
  const formContext = useFormContext();
  const filtersContext = useContext<any>(FiltersContext);
  const travelers = filtersContext.searchQuery.travelers;

  useEffect(() => {
    if (formContext) {
      formContext.setValue(name, initialValue);
    }
  }, []);

  const minimumValue = useMemo(() => {
    return name === "adults" || name === "rooms" ? 1 : 0;
  }, [name]);

  const maximumValue = useMemo(() => {
    return name === "rooms" ? 9 : null;
  }, [name]);

  return (
    <div className="flex flex-row justify-between">
      <label className="flex flex-col justify-center">
        <p className="text-p font-medium">
          {label}
          {hasAsterisk ? "*" : ""}
        </p>
        {sublabel && <p className="text-p13 text-midgray">{sublabel}</p>}
      </label>
      <div className="flex flex-row items-center">
        <MinusButton
          className="h-[36px] w-[36px] bg-orange"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();
            if (formContext) {
              if (
                typeof formContext.getValues(name) === "string"
                  ? Number(formContext.getValues(name).substring(0, 1)) >
                    minimumValue
                  : formContext.getValues(name) > minimumValue
              ) {
                formContext.setValue(
                  name,
                  typeof formContext.getValues(name) === "string"
                    ? Number(formContext.getValues(name).substring(0, 1)) - 1
                    : formContext.getValues(name) - 1
                );

                const userSearchFilters = JSON.parse(
                  //@ts-ignore
                  localStorage.getItem(LOCAL_STORAGE.userSearchFilters)
                );

                localStorage.setItem(
                  LOCAL_STORAGE.userSearchFilters,
                  userSearchFilters
                    ? JSON.stringify({
                        ...userSearchFilters,
                        travelers: {
                          ...userSearchFilters.travelers,
                          [name]: formContext.getValues(name),
                        },
                      })
                    : JSON.stringify({
                        travelers: {
                          [name]: formContext.getValues(name),
                        },
                      })
                );

                filtersContext.updateTravelers(
                  name,
                  Number(travelers[name]) - 1,
                  minimumValue,
                  maximumValue
                );
              }
            } else {
              filtersContext.updateTravelers(
                name,
                Number(travelers[name]) - 1,
                minimumValue - 1,
                maximumValue !== null ? maximumValue + 1 : maximumValue
              );
            }
          }}
        />
        {formContext ? (
          <input
            className="mx-auto max-w-[2rem] text-center text-p font-medium"
            {...formContext.register(name)}
          />
        ) : (
          <input
            className="mx-auto max-w-[2rem] bg-blue-5 text-center text-p font-medium"
            value={
              travelers[name] === 9 ? `${travelers[name]}+` : travelers[name]
            }
            readOnly
          />
        )}
        <PlusButton
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();
            if (formContext) {
              if (!maximumValue || formContext.getValues(name) < maximumValue) {
                formContext.setValue(name, formContext.getValues(name) + 1);

                const userSearchFilters = JSON.parse(
                  //@ts-ignore
                  localStorage.getItem(LOCAL_STORAGE.userSearchFilters)
                );

                localStorage.setItem(
                  LOCAL_STORAGE.userSearchFilters,
                  userSearchFilters
                    ? JSON.stringify({
                        ...userSearchFilters,
                        travelers: {
                          ...userSearchFilters.travelers,
                          [name]: formContext.getValues(name),
                        },
                      })
                    : JSON.stringify({
                        travelers: {
                          [name]: formContext.getValues(name),
                        },
                      })
                );

                filtersContext.updateTravelers(
                  name,
                  Number(travelers[name]) + 1,
                  minimumValue,
                  maximumValue
                );

                if (formContext.getValues("rooms") === 9) {
                  formContext.setValue(
                    "rooms",
                    `${formContext.getValues("rooms")}+`
                  );
                }
              }
            } else {
              filtersContext.updateTravelers(
                name,
                Number(travelers[name]) + 1,
                minimumValue - 1,
                maximumValue !== null ? maximumValue + 1 : maximumValue
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export default InputCounter;
