export interface IStorageEntry {
  id: number;
  likedAt: Date;
}

type GetStorageLikesResponse = () => Promise<{
  storagePackages: IStorageEntry[];
  storageAccommodations: IStorageEntry[];
  storageActivities: IStorageEntry[];
  storageLocations: IStorageEntry[];
}>;

const getStorageLikes: GetStorageLikesResponse = async (): Promise<any> => {
  const moment = (await import("moment")).default;

  const storagePackages: IStorageEntry[] = localStorage.getItem("PAC_LIKE")
    ? JSON.parse(localStorage.getItem("PAC_LIKE") ?? "[]")?.map(
        (pack: IStorageEntry) => {
          return { id: pack.id, likedAt: moment().format() };
        }
      )
    : [];
  const storageAccommodations: IStorageEntry[] = localStorage.getItem(
    "ACC_LIKES"
  )
    ? JSON.parse(localStorage.getItem("ACC_LIKES") ?? "[]")?.map(
        (pack: IStorageEntry) => {
          return { id: pack.id, likedAt: moment().format() };
        }
      )
    : [];
  const storageActivities: IStorageEntry[] = localStorage.getItem("ACT_LIKES")
    ? JSON.parse(localStorage.getItem("ACT_LIKES") ?? "[]")?.map(
        (pack: IStorageEntry) => {
          return { id: pack.id, likedAt: moment().format() };
        }
      )
    : [];
  const storageLocations: IStorageEntry[] = localStorage.getItem("LOC_LIKES")
    ? JSON.parse(localStorage.getItem("LOC_LIKES") ?? "[]")?.map(
        (pack: IStorageEntry) => {
          return { id: pack.id, likedAt: moment().format() };
        }
      )
    : [];

  return {
    storagePackages,
    storageAccommodations,
    storageActivities,
    storageLocations,
  };
};

export default getStorageLikes;
