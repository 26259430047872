import {
  useState,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { useRouter } from "next/router";
// @ts-expect-error
import { START_DATE } from "react-dates/constants";
import useFormatDate from "./useFormatDate";
import { LOCAL_STORAGE } from "@components/utils/constants";
import { Moment } from "moment";
import { FocusedInputShape } from "react-dates";
const moment = (await import("moment")).default;

export interface IUseCalendarReturn {
  showCalendar: boolean;
  setShowCalendar: Dispatch<SetStateAction<boolean>>;
  calendarStatus: boolean;
  setCalendarStatus: Dispatch<SetStateAction<boolean>>;
  inputDate: string | null;
  setInputDate: Dispatch<SetStateAction<string | null>>;
  focusedInput: FocusedInputShape | null;
  setFocusedInput: Dispatch<SetStateAction<FocusedInputShape | null>>;
  monthsToShow: number;
  setMonthsToShow: Dispatch<SetStateAction<number>>;
  startDateState: Moment | null;
  setStartDate: Dispatch<SetStateAction<Moment | null>>;
  endDateState: Moment | null;
  setEndDate: Dispatch<SetStateAction<Moment | null>>;
  hasLoadedOnce: boolean;
  setHasLoadedOnce: Dispatch<SetStateAction<boolean>>;
  resetToLastValues: () => void;
  resetValues: () => void;
}

const useCalendar = (): IUseCalendarReturn => {
  const router = useRouter();
  const { startDate, endDate } = router.query;
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarStatus, setCalendarStatus] = useState(false);
  const [inputDate, setInputDate] = useState<string | null>(null);
  const [focusedInput, setFocusedInput] = useState(START_DATE);
  const [monthsToShow, setMonthsToShow] = useState(2);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [shouldCleanValues, setShouldCleanValues] = useState(false);
  const [startDateState, setStartDate] = useState(
    startDate
      ? moment(String(startDate).replaceAll(" ", "+"))
      : typeof window !== "undefined" &&
        JSON.parse(
          String(localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams))
        )?.startDate
      ? localStorage.getItem("USER_REQUEST_QUOTE")
        ? moment(
            JSON.parse(String(localStorage.getItem("USER_REQUEST_QUOTE")))
              .startDate
          )
        : moment(
            JSON.parse(
              String(localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams))
            ).startDate
          )
      : null
  );
  const [endDateState, setEndDate] = useState(
    endDate
      ? moment(String(endDate).replaceAll(" ", "+"))
      : typeof window !== "undefined" &&
        JSON.parse(
          String(localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams))
        )?.endDate
      ? localStorage.getItem("USER_REQUEST_QUOTE")
        ? moment(
            JSON.parse(String(localStorage.getItem("USER_REQUEST_QUOTE")))
              .endDate
          )
        : moment(
            JSON.parse(
              String(localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams))
            ).endDate
          )
      : null
  );

  const resetToLastValues = useCallback(() => {
    setStartDate(
      startDate
        ? moment(String(startDate).replaceAll(" ", "+"))
        : typeof window !== "undefined" &&
          JSON.parse(
            String(localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams))
          )?.startDate
        ? localStorage.getItem("USER_REQUEST_QUOTE")
          ? moment(
              JSON.parse(String(localStorage.getItem("USER_REQUEST_QUOTE")))
                .startDate
            )
          : moment(
              JSON.parse(
                String(
                  localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams)
                )
              ).startDate
            )
        : null
    );
    setEndDate(
      endDate
        ? moment(String(endDate).replaceAll(" ", "+"))
        : typeof window !== "undefined" &&
          JSON.parse(
            String(localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams))
          )?.endDate
        ? localStorage.getItem("USER_REQUEST_QUOTE")
          ? moment(
              JSON.parse(String(localStorage.getItem("USER_REQUEST_QUOTE")))
                .endDate
            )
          : moment(
              JSON.parse(
                String(
                  localStorage.getItem(LOCAL_STORAGE.userSearchQueryParams)
                )
              ).endDate
            )
        : null
    );
    setFocusedInput(START_DATE);
    setMonthsToShow(1);
    setInputDate(null);
  }, [startDate, setStartDate, endDate, setEndDate, setMonthsToShow]);

  useFormatDate(setInputDate, [startDateState, endDateState]);

  const resetValues = (): void => {
    setInputDate(null);
    setStartDate(null);
    setEndDate(null);
    setShouldCleanValues(true);
    localStorage.setItem(
      LOCAL_STORAGE.userSearchQueryParams,
      JSON.stringify({ startDate: null, endDate: null })
    );
  };

  useEffect(() => {
    if (shouldCleanValues) {
      resetValues();
      setShouldCleanValues(false);
    }
  }, [router.query]);

  return {
    showCalendar,
    setShowCalendar,
    calendarStatus,
    setCalendarStatus,
    inputDate,
    setInputDate,
    focusedInput,
    setFocusedInput,
    monthsToShow,
    setMonthsToShow,
    startDateState,
    setStartDate,
    endDateState,
    setEndDate,
    hasLoadedOnce,
    setHasLoadedOnce,
    resetToLastValues,
    resetValues,
  };
};

export default useCalendar;
