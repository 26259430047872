import React from "react";
import useAppContext from "@hooks/useAppContext";
import MultiRangePrice from "@components/common/requestQuote/MultiRangePrice";
import AccommodationTypeButtons from "./AccommodationTypeButtons";
import AccommodationPropertyList from "./AccommodationPropertyList";

const AccommodationStep = (): JSX.Element => {
  const { isMobile } = useAppContext();
  return (
    <div className="mx-5">
      <div className="my-6">
        <AccommodationTypeButtons />
      </div>
      <div className={`${!isMobile && "px-[5.75rem]"}`}>
        <div className="flex flex-col gap-3">
          <p className="text-p font-medium">Lodging Budget</p>
          <p className="mb-1 text-p14 text-darkgray">Price range, per night</p>
          <MultiRangePrice isSearch={true} />
        </div>
        <AccommodationPropertyList />
      </div>
    </div>
  );
};

export default AccommodationStep;
