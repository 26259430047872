import React, { useEffect, useMemo, useCallback } from "react";
import useAppContext from "@hooks/useAppContext";
import useModalScrollFreeze from "@hooks/useModalScrollFreeze";
import { getCookie, setCookie } from "cookies-next";
import MediaAssets from "@components/utils/MediaAssets";
import useAnalytics from "@hooks/useAnalytics";
import dynamic from "next/dynamic";
import { IAppPagePropsResponse } from "../../types/commonResponses";
import { NextPage } from "next";
import ServerErrorPage from "pages/500";
import { PAGE_TYPES } from "@customTypes/index";
import RedirectingPage from "pages/redirecting";

const Header = dynamic(async () => await import("@components/header/Header"), {
  ssr: true,
});
const HomeFooter = dynamic(
  async () => await import("@components/footer/Footer"),
  {
    ssr: true,
  }
);
const HeaderShadowMobile = dynamic(
  async () => await import("@components/header/HeaderShadowMobile"),
  { ssr: true }
);
const MetaTags = dynamic(
  async () => await import("@components/head/MetaTags"),
  {
    ssr: true,
  }
);

const Toast = dynamic(async () => await import("@components/common/Toast"), {
  ssr: true,
});

const AuthModal = dynamic(
  async () => await import("@components/common/AuthModal"),
  {
    ssr: true,
  }
);
const FilterModal = dynamic(
  async () => await import("@components/SearchManagement/FilterModal"),
  { ssr: true }
);

export interface IAppContent {
  Component: NextPage<IAppPagePropsResponse>;
  pageProps: IAppPagePropsResponse;
}

const AppContent = ({ Component, pageProps }: IAppContent): JSX.Element => {
  const appContext = useAppContext();

  useModalScrollFreeze([
    appContext?.showHeaderMenuMobile,
    appContext?.showLoginModal,
    appContext?.isLoadingAuthAction.loggingIn,
    appContext?.isLoadingAuthAction.loggingOut,
  ]);

  const authModalParams = useMemo(() => {
    let title = "";
    const body = "This may take a moment";
    if (appContext?.isLoadingAuthAction.loggingIn) {
      title = "Logging in...";
    } else if (appContext?.isLoadingAuthAction.loggingOut) {
      title = "Logging out...";
    }
    return {
      title,
      body,
    };
  }, [appContext?.isLoadingAuthAction]);

  const handleClose = useCallback(() => {
    appContext?.setShowToastMessage(false);
  }, []);

  useEffect(() => {
    /* Validate that the cookie does not exist */
    if (!getCookie("roasurl")) {
      /* Create a variable with the url */
      const urlVariables = location.search.substring(1);
      const thirtyDaysInSeconds = 30 * 24 * 60 * 60;
      /* Valid that the url contains "utm" */
      if (urlVariables.includes("utm")) {
        setCookie("roasurl", window.location.href, {
          maxAge: thirtyDaysInSeconds,
        }); /* save cookie for 30 days */
        /* If the url does not contain utm sabe the referrer */
      } else if (document.referrer) {
        setCookie("roasurl", document.referrer, {
          maxAge: thirtyDaysInSeconds,
        });
        /* If the url does not contain utm and the referrer is false save the first url */
      } else {
        setCookie("roasurl", window.location.href, {
          maxAge: thirtyDaysInSeconds,
        });
      }
    }

    // This is used to mark when a page is completely rendered so prerender.io can take a snapshot of it
    // 3 seconds is the time chosen to ensure that images have finished downloading
    setTimeout(() => {
      // @ts-expect-error
      window.prerenderReady = true;
    }, 3000);
  }, []);

  useAnalytics(pageProps);

  MediaAssets.loadAssets(pageProps?.mediaAssets);

  return (
    <>
      <MetaTags {...pageProps} />
      <div id="root-body" className="w-full">
        {(appContext?.isLoadingAuthAction.loggingIn ||
          appContext?.isLoadingAuthAction.loggingOut) && (
          <AuthModal
            title={authModalParams.title}
            body={authModalParams.body}
          />
        )}
        {pageProps.pageType === PAGE_TYPES.RedirectingPage ? (
          <RedirectingPage />
        ) : (
          <>
            <Header {...pageProps} />
            {appContext?.showToastMessage && (
              <Toast
                position="fixed"
                text={appContext?.toastMessage}
                onClose={handleClose}
              />
            )}
            {pageProps.hasInternalError ? (
              // Handles statusCode 500
              <ServerErrorPage />
            ) : (
              <Component {...pageProps} />
            )}
            {appContext.isMobile && <HeaderShadowMobile />}
            <HomeFooter
              footer={pageProps?.footer}
              topNavigation={pageProps?.topNavigation}
              usp={pageProps?.usp}
              pageType={pageProps?.pageType}
            />
            <FilterModal />
          </>
        )}
      </div>
    </>
  );
};

export default AppContent;
