import { buildUrl } from "cloudinary-build-url";
import React, { useState } from "react";
import useAppContext from "@hooks/useAppContext";
import Image from "next/legacy/image";
import getConfig from "next/config";
import { ImageDefault } from "@components/utils/Utils";
import { IMAGE_QUALITY } from "@components/utils/constants";

declare type SafeNumber = number | `${number}`;

interface IImageCDNProps {
  // size,
  src: string;
  imageName?: string;
  width?: SafeNumber;
  height?: SafeNumber;
  alt?: string;
  className?: string;
  layout?: "fill" | "fixed" | "intrinsic" | "responsive";
  objectFit?: any;
  onClick?: React.MouseEventHandler<HTMLImageElement>;
  priority?: boolean;
}

export const customLoader = ({ src, width, quality }: any): string => {
  return src;
};

const shimmer = (
  w: number | string | undefined,
  h: number | string | undefined
): string => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#E8E8E8" offset="20%" />
      <stop stop-color="#E9E9E9" offset="50%" />
      <stop stop-color="#E8E8E8" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#E8E8E8" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string): string =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

function ImageCDN({
  // size,
  src,
  imageName,
  width,
  alt,
  height,
  className,
  layout,
  objectFit,
  onClick,
  priority = false,
}: IImageCDNProps): JSX.Element {
  const { publicRuntimeConfig } = getConfig();
  const [hasError, setHasError] = useState(false);
  const { isMobile } = useAppContext();

  let cloudinarytUrl = ImageDefault(null);
  if (src) {
    const arr = src.split("/");
    imageName = arr[7];
    cloudinarytUrl = buildUrl(
      `${publicRuntimeConfig.FOLDER_NAME}/${imageName}`,
      {
        cloud: {
          cloudName: publicRuntimeConfig.CLOUD_NAME,
        },
        transformations: {
          height,
          width,
          quality: isMobile ? IMAGE_QUALITY.MOBILE : IMAGE_QUALITY.DESKTOP,
          // effect: "improve",
          crop: "fill",
          format: "webp",
        },
      }
    );
  }

  return (
    <Image
      loader={customLoader}
      className={className}
      src={hasError ? ImageDefault(null) : cloudinarytUrl}
      alt={alt}
      width={width}
      height={height}
      layout={layout}
      objectFit={objectFit}
      onClick={onClick}
      priority={priority}
      loading={priority ? undefined : "lazy"}
      placeholder="blur"
      blurDataURL={`data:image/svg+xml;base64,${toBase64(
        shimmer(width, height)
      )}`}
      onError={() => setHasError(true)}
    />
  );
}

export default ImageCDN;
