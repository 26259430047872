import moment, { Moment } from "moment";

function isInclusivelyAfterDay(a: Moment | null, b: Moment | null): boolean {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  return !isBeforeDay(a, b);
}
function isBeforeDay(a: Moment | null, b: Moment | null): boolean {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
}
const startDateId = `startDate${Date.now()}`;
const dateRangePickerConfigNamu = {
  // example props for the demo
  startDateId,
  autoFocusEndDate: false,
  initialStartDate: null,
  initialEndDate: null,
  startDateOffset: undefined,
  endDateOffset: undefined,
  renderWeekHeaderElement: (day: string) => day[0],
  showInputs: false,
  minDate: null,
  maxDate: null,
  hideKeyboardShortcutsPanel: true,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  isDayBlocked: () => false,
  isOutsideRange: (day: Moment) => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,
  enableOutsideDays: false,
  daysViolatingMinNightsCanBeClicked: false,

  // calendar presentation and interaction related props
  verticalHeight: undefined,
  withPortal: true,
  numberOfMonths: 2,

  keepOpenOnDateSelect: false,
  // internationalization
  monthFormat: "MMMM YYYY",
};

export default dateRangePickerConfigNamu;
