import React from "react";
import useAppContext from "@hooks/useAppContext";
import MediaAssets from "@components/utils/MediaAssets";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import MainWrapper from "@components/pages/common/MainWrapper";

const ServerErrorComponent = ({ cf = false }: { cf: boolean }): JSX.Element => {
  const router = useRouter();
  const { isMobile } = useAppContext();

  return (
    <MainWrapper>
      {isMobile ? (
        // Mobile 500
        <div className="relative h-fit min-h-screen">
          <div className="relative flex h-[250px] justify-center bg-[#e5e5e5] pt-[23px]">
            <Image
              src={MediaAssets.getAsset("frog_404_page")}
              height="204"
              width="350"
              layout="fixed"
              alt="404 frog image"
            />
          </div>
          <div className="mb-14 ml-12 mr-2 mt-12 w-fit">
            <p className="font-semibold text-darkgray">
              Ooops... page not found.
            </p>
            <Image
              src={MediaAssets.getAsset("500")}
              width="193"
              height="96"
              layout="fixed"
              alt="500 page not found"
            />
            <p className="text-darkgray">
              We are currently unable to handle this request due to a server
              error.
              <br />
              Try to refresh this page or contact us if the problem persists.
            </p>
            {cf && (
              <p className="text-darkgray">::CLOUDFLARE_ERROR_500S_BOX::</p>
            )}
          </div>
          <div className="mx-auto flex h-[85px] w-[100%] max-w-[390px] items-center justify-center rounded-full border-[24px] border-white">
            <button
              onClick={() => router.back()}
              className="bottom-6 left-4 mx-auto flex w-[100%] max-w-[350px] justify-center rounded-full bg-orange-100 p-5 text-white hover:bg-orange"
            >
              Go Back
            </button>
          </div>
          <div className="absolute bottom-0 right-0 -z-10">
            <Image
              src={MediaAssets.getAsset("whitecard_waves")}
              width="776"
              height="358"
              layout="fixed"
              alt="whitecard waves background"
            />
          </div>
        </div>
      ) : (
        // Desktop 404
        <div className="h-full items-center justify-center desktop:flex">
          <div className="relative my-[200px] h-[400px] w-[1300px] overflow-hidden rounded-2xl bg-blue-5">
            <div className="flex h-[400px] w-[1300px] items-center">
              <div>
                <Image
                  className="w-[400px]"
                  src={MediaAssets.getAsset("frog_404_page")}
                  height="273"
                  width="468"
                  layout="fixed"
                  alt="frog 404 page"
                />
              </div>
              <div className="ml-12 flex w-full flex-col gap-4">
                <p className="font-semibold text-darkgray">
                  Ooops ... Internal server error.
                </p>
                <Image
                  src={MediaAssets.getAsset("500")}
                  width="193"
                  height="96"
                  layout="fixed"
                  alt="404 page not found"
                />
                <p className="w-full text-darkgray">
                  We are currently unable to handle this request due to a server
                  error.
                  <br />
                  Try to refresh this page or contact us if the problem
                  persists.
                </p>

                {cf && (
                  <p className="w-full text-darkgray">
                    ::CLOUDFLARE_ERROR_500S_BOX::
                  </p>
                )}
                <button
                  onClick={() => router.back()}
                  className="bottom-6 left-4 mt-3 flex h-[58px]  w-[118px] items-center justify-center rounded-full bg-orange-100 px-[24px] text-white hover:bg-orange"
                >
                  Go Back
                </button>
              </div>
              <div className="absolute right-0 h-[400px] w-[600px]">
                <Image
                  src={MediaAssets.getAsset("whitecard_waves")}
                  width="600"
                  height="400"
                  layout="fill"
                  objectFit="cover"
                  alt="whitecard waves background"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </MainWrapper>
  );
};

export default ServerErrorComponent;
