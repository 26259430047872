import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  createContext,
} from "react";
import { useLike } from "@hooks/useLike";
import getStorageLikes, { IStorageEntry } from "@libs/likes/utils";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import {
  IPackageResponse,
  ILocationResponse,
  IAccommodationResponse,
  IActivityResponse,
} from "../types/modelResponses";
import { USER_PREF_SYNC } from "@components/home/routes";
import { Amplify, Auth } from "aws-amplify";
// @ts-expect-error
import NextStorage from "amplify-auth-next-cookie-storage";
import { NextApiRequest } from "next";
import AmplifyConfigurationCookie from "@services/AmplifyConfigurationCookie";
export interface ISessionContext {
  session: ISession | null;
  refreshSession: () => Promise<void>;
  fetchUser: () => Promise<void>;
  setSession: React.Dispatch<React.SetStateAction<ISession | null>>;
  isSessionLoading: boolean;
}

export interface IPackagePreference extends IPackageResponse {
  LikedAt: string;
}

export interface ILocationPreference extends ILocationResponse {
  LikedAt: string;
}

export interface IAccommodationPreference extends IAccommodationResponse {
  LikedAt: string;
}

export interface IActivityPreference extends IActivityResponse {
  LikedAt: string;
}

export interface ISession {
  accessToken: IAccessToken;
  idToken: IidToken;
  userPreferences: {
    packages: IPackagePreference[];
    locations: ILocationPreference[];
    accommodations: IAccommodationPreference[];
    activities: IActivityPreference[];
  };
}

interface IAccessToken {
  jwtToken: string;
  payload: {
    auth_time: number;
    client_id: string;
    cognito: string[];
    exp: number;
    iat: number;
    iss: string;
    jti: string;
    scope: string;
    sub: string;
    token_use: string;
    username: string;
    version: number;
  };
}

interface IidToken {
  jwtToken: string;
  payload: {
    name?: string;
    phone?: string;
    email: string;
    email_verified: string;
    sub: string;
    "cognito:username"?: string;
  };
}

interface ICognitoUserResponse extends CognitoUserSession {
  idToken: IidToken;
  accessToken: IAccessToken;
}

const SessionContext = createContext<ISessionContext>({
  session: null,
  refreshSession: async () => {},
  fetchUser: async () => {},
  setSession: () => {},
  isSessionLoading: true,
});

const configureAmplify = (ctx: NextApiRequest | null): void => {
  const cookieConfiguration = AmplifyConfigurationCookie();
  Amplify.configure({
    Auth: {
      region: process.env.COGNITO_POOL_REGION,
      userPoolId: process.env.COGNITO_POOL_ID,
      userPoolWebClientId: process.env.COGNITO_POOL_WEBCLIENT_ID,
      oauth: {
        domain: process.env.OAUTH_DOMAIN,
        scope: ["phone", "email", "openid", "profile"],
        redirectSignIn: process.env.SIGN_IN_REDIRECT_URL,
        redirectSignOut: process.env.SIGN_OUT_REDIRECT_URL,
        responseType: "token",
      },
      storage: new NextStorage(ctx, {
        domain: cookieConfiguration.domain,
        secure: cookieConfiguration.isHTTPS,
        path: cookieConfiguration.path,
        expires: cookieConfiguration.expire,
      }),
    },
    ssr: true,
  });
};

const SessionProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}): JSX.Element => {
  configureAmplify(null);
  const [isSessionLoading, setIsSessionLoading] = useState(true);

  const [session, setSession] = useState<ISession | null>(null);
  const { syncSessionLikes } = useLike();

  const fetchUser = useCallback(async () => {
    setIsSessionLoading(true);

    Auth.currentSession()
      .then(async (user): Promise<void> => {
        // @ts-expect-error
        const userRes: ICognitoUserResponse = user;
        const userEmail = userRes.idToken.payload.email;
        const cognitoId = userRes.idToken.payload.sub;

        const {
          storagePackages,
          storageAccommodations,
          storageActivities,
          storageLocations,
        } = await getStorageLikes();

        const newUserData = {
          data: {
            User_Email: userEmail,
            Cognito_Id: cognitoId,
            Packages: storagePackages,
            Accommodations: storageAccommodations,
            Activities: storageActivities,
            Locations: storageLocations,
          },
        };

        await syncData(userRes, newUserData);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsSessionLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchUser().catch((error) => {
      console.log(error);
    });
  }, []);

  const refreshSession = useCallback(async () => {
    setIsSessionLoading(true);

    Auth.currentSession()
      .then(async (user): Promise<void> => {
        // @ts-expect-error
        const userRes: ICognitoUserResponse = user;
        const userEmail = userRes.idToken.payload.email;
        const cognitoId = userRes.idToken.payload.sub;

        const {
          storagePackages,
          storageAccommodations,
          storageActivities,
          storageLocations,
        } = await getStorageLikes();

        const newUserData = {
          data: {
            User_Email: userEmail,
            Cognito_Id: cognitoId,
            Packages: storagePackages,
            Accommodations: storageAccommodations,
            Activities: storageActivities,
            Locations: storageLocations,
          },
        };

        await syncData(userRes, newUserData);
      })
      .catch((e) => {
        // console.log(e);
        setSession(null);
      })
      .finally(() => {
        setIsSessionLoading(false);
      });
  }, []);

  const syncData = async (
    user: ICognitoUserResponse,
    newUserData: {
      data: {
        User_Email: string;
        Cognito_Id: string;
        Packages: IStorageEntry[];
        Accommodations: IStorageEntry[];
        Activities: IStorageEntry[];
        Locations: IStorageEntry[];
      };
    }
  ): Promise<void> => {
    try {
      const syncRes = await fetch(USER_PREF_SYNC, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newUserData),
      }).then(async (res) => await res.json());

      setSession({
        ...user,
        userPreferences: syncRes,
      });
      syncSessionLikes(syncRes);
    } catch (err) {
      console.log(err);
    }
  };

  const providerValues = useMemo(
    () => ({
      session,
      refreshSession,
      fetchUser,
      setSession,
      isSessionLoading,
    }),
    [session, fetchUser, isSessionLoading]
  );

  return (
    <SessionContext.Provider value={providerValues}>
      {children}
    </SessionContext.Provider>
  );
};

export { SessionProvider, SessionContext };
