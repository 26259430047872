import React from "react";
import useAppContext from "@hooks/useAppContext";
import ModalContainer from "./components/ModalContainer";
import BackDropDark from "@components/common/BackDropDark";
import useModalScrollFreeze from "hooks/useModalScrollFreeze";
import ReactDom from "react-dom";

export interface IFormModalProps {
  title: string;
  subtitle?: string;
  maxDesktopWidth?: number;
  closeModalHandler?: () => void;
  hasLogo?: boolean;
  backModalHandler?: () => void;
  children: JSX.Element | JSX.Element[];
  zIndex?: number;
  isFilterModal?: boolean;
  hasBorder?: boolean;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
}

const FormModal = ({
  title,
  subtitle,
  maxDesktopWidth,
  closeModalHandler,
  hasLogo,
  backModalHandler,
  children,
  isFilterModal,
  hasBorder,
  zIndex,
  scrollContainerRef,
}: IFormModalProps): JSX.Element => {
  const { isMobile } = useAppContext();

  // Freezes only desktop modal
  useModalScrollFreeze([!isMobile]);

  return ReactDom.createPortal(
    <>
      <BackDropDark closeModalHandler={closeModalHandler} />
      <ModalContainer
        title={title}
        subtitle={subtitle}
        maxDesktopWidth={maxDesktopWidth}
        hasLogo={hasLogo}
        onBackHandler={backModalHandler}
        onCloseHandler={closeModalHandler}
        zIndex={zIndex}
        isFilterModal={isFilterModal}
        hasBorder={hasBorder}
        scrollContainerRef={scrollContainerRef}
      >
        {children}
      </ModalContainer>
    </>,
    document.getElementById("root-body")!
  );
};

export default FormModal;
