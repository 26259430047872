import React from "react";

const CustomWeekdayHeader = (): JSX.Element => {
  return (
    <ul className="mx-auto flex w-fit flex-row items-center gap-[1px] font-semibold text-gray ">
      <li className="w-[38px] text-center">S</li>
      <li className="w-[38px] text-center">M</li>
      <li className="w-[38px] text-center">T</li>
      <li className="w-[38px] text-center">W</li>
      <li className="w-[38px] text-center">T</li>
      <li className="w-[38px] text-center">F</li>
      <li className="w-[38px] text-center">S</li>
    </ul>
  );
};

export default CustomWeekdayHeader;
