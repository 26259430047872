import { ISessionContext, SessionContext } from "providers/SessionProvider";
import { useContext } from "react";

const useSession = (): ISessionContext => {
  const context = useContext(SessionContext);

  return context;
};

export default useSession;
