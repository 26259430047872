import { FILTER, TAG_TYPES } from "@components/utils/constants";
import {
  ISearchQuery,
  ISearchManagement,
  IBodyParams,
} from "../../types/filtersProvider";

export const bodyParamsToSearchQueryObject = (
  bodyParams: IBodyParams,
  searchManagement: ISearchManagement,
  initialSearchQueryState: ISearchQuery
): ISearchQuery => {
  const searchManagementHotels =
    searchManagement?.[FILTER.ACCOMMODATIONS.filterType]?.hotels.categories ??
    [];
  const searchManagementRentals =
    searchManagement?.[FILTER.ACCOMMODATIONS.filterType]?.rentals.categories ??
    [];

  const selectedHotels = bodyParams.propertyTypes
    ? searchManagementHotels
        .filter((propertyType) => {
          return bodyParams.propertyTypes.includes(
            propertyType[FILTER.ACCOMMODATIONS.strapiName].title
          );
        })
        .map((propertyType) => {
          return {
            id: propertyType[FILTER.ACCOMMODATIONS.strapiName].id,
            title: propertyType[FILTER.ACCOMMODATIONS.strapiName].title,
            isChecked: true,
            propertyCategory: FILTER.ACCOMMODATIONS.hotelTitle,
          };
        })
    : [];
  const selectedRentals = bodyParams.propertyTypes
    ? searchManagementRentals
        .filter((propertyType) => {
          return bodyParams.propertyTypes.includes(
            propertyType[FILTER.ACCOMMODATIONS.strapiName].title
          );
        })
        .map((propertyType) => {
          return {
            id: propertyType[FILTER.ACCOMMODATIONS.strapiName].id,
            title: propertyType[FILTER.ACCOMMODATIONS.strapiName].title,
            isChecked: true,
            propertyCategory: FILTER.ACCOMMODATIONS.rentalTitle,
          };
        })
    : [];

  const isHotelIconSelected = bodyParams.mainPropertyTypes
    ? bodyParams.mainPropertyTypes.includes(FILTER.ACCOMMODATIONS.hotelTitle)
    : false;
  const isRentalIconSelected = bodyParams.mainPropertyTypes
    ? bodyParams.mainPropertyTypes.includes(FILTER.ACCOMMODATIONS.rentalTitle)
    : false;
  const isAllHotelsInURL = bodyParams.mainPropertyTypes?.includes(
    FILTER.ACCOMMODATIONS.allHotelsSelectedParam
  );

  const isAllRentalsInURL = bodyParams.mainPropertyTypes?.includes(
    FILTER.ACCOMMODATIONS.allRentalsSelectedParam
  );

  const areAllHotelsSelected =
    selectedHotels.length === searchManagementHotels.length;
  const areAllRentalsSelected =
    selectedRentals.length === searchManagementRentals.length;

  return {
    ...initialSearchQueryState,
    experiences: bodyParams.tags
      ? searchManagement[FILTER.EXPERIENCE.filterType]
          ?.filter((experience) => {
            return bodyParams.tags.includes(
              experience[FILTER.EXPERIENCE.strapiName].title
            );
          })
          .map((experience) => {
            return {
              id: experience[FILTER.EXPERIENCE.strapiName].id,
              title: experience[FILTER.EXPERIENCE.strapiName].title,
              isChecked: true,
              type: TAG_TYPES.TAG,
            };
          })
      : initialSearchQueryState.experiences,

    locationTypes: bodyParams.locationTypes
      ? searchManagement[FILTER.LOCATION_TYPE.filterType]
          ?.filter((locationType) => {
            return bodyParams.locationTypes.includes(
              locationType[FILTER.LOCATION_TYPE.strapiName].title
            );
          })
          .map((locationType) => {
            return {
              id: locationType[FILTER.LOCATION_TYPE.strapiName].id,
              title: locationType[FILTER.LOCATION_TYPE.strapiName].title,
              isChecked: true,
              type: TAG_TYPES.LOCATION_TYPE,
            };
          })
      : initialSearchQueryState.locationTypes,

    activityTypes: bodyParams.activityTypes
      ? searchManagement[FILTER.ACTIVITY_TYPE.filterType]
          ?.filter((activityType) => {
            return bodyParams.activityTypes.includes(
              activityType[FILTER.ACTIVITY_TYPE.strapiName].title
            );
          })
          .map((activityType) => {
            return {
              id: activityType[FILTER.ACTIVITY_TYPE.strapiName].id,
              title: activityType[FILTER.ACTIVITY_TYPE.strapiName].title,
              isChecked: true,
              type: TAG_TYPES.ACTIVITY_TYPE,
            };
          })
      : initialSearchQueryState.activityTypes,

    accommodations: {
      budget: {
        min: bodyParams.minBudget
          ? Number(bodyParams.minBudget)
          : initialSearchQueryState.accommodations.budget.min,
        max: bodyParams.maxBudget
          ? Number(bodyParams.maxBudget)
          : initialSearchQueryState.accommodations.budget.max,
        isTouched: !!(bodyParams.minBudget && bodyParams.maxBudget),
      },
      hotels: {
        categories: areAllHotelsSelected
          ? isAllHotelsInURL
            ? selectedHotels
            : []
          : selectedHotels,
        isChecked: isHotelIconSelected,
        title: FILTER.ACCOMMODATIONS.hotelTitle,
      },
      rentals: {
        categories: areAllRentalsSelected
          ? isAllRentalsInURL
            ? selectedRentals
            : []
          : selectedRentals,
        isChecked: isRentalIconSelected,
        title: FILTER.ACCOMMODATIONS.rentalTitle,
      },
    },

    travelers: {
      rooms: bodyParams.rooms
        ? bodyParams.rooms
        : initialSearchQueryState.travelers.rooms,
    },
  };
};

export const budgetParamsToSearchQueryObject = (
  bodyParams: IBodyParams,
  searchQueryState: ISearchQuery
): ISearchQuery => {
  return {
    ...searchQueryState,
    accommodations: {
      ...searchQueryState?.accommodations,
      budget: {
        ...searchQueryState?.accommodations?.budget,
        min: bodyParams.minBudget
          ? Number(bodyParams.minBudget)
          : searchQueryState.accommodations.budget.min,
        max: bodyParams.maxBudget
          ? Number(bodyParams.maxBudget)
          : searchQueryState.accommodations.budget.max,
      },
    },
  };
};
