import React, { Context, useEffect, useContext } from "react";
import dateRangePickerConfigNamu from "@components/utils/dateRangePickerConfigNamu";
import "react-dates/initialize";
// @ts-expect-error
import { START_DATE, END_DATE } from "react-dates/constants";
import dynamic from "next/dynamic";
import { FiltersContext, IFiltersContext } from "providers/FiltersProvider";
import { IUseCalendarReturn } from "@hooks/useCalendar";
import { FocusedInputShape } from "react-dates";
import { Moment } from "moment";
const DayPickerRangeController = dynamic(
  async () =>
    await import("react-dates/lib/components/DayPickerRangeController")
);

interface IHorizontalCalendar
  extends Pick<
    IUseCalendarReturn,
    | "setStartDate"
    | "setFocusedInput"
    | "focusedInput"
    | "startDateState"
    | "endDateState"
    | "setEndDate"
    | "setInputDate"
  > {
  isMobilePopUp?: boolean;
  observe?: string;
}

const HorizontalCalendar = ({
  setInputDate,
  startDateState,
  setStartDate,
  endDateState,
  setEndDate,
  focusedInput,
  setFocusedInput,
}: IHorizontalCalendar): JSX.Element => {
  const { changeCalendarSelection } = useContext<IFiltersContext>(
    FiltersContext as Context<IFiltersContext>
  );
  const onDatesUpdate = (dates: {
    startDate: Moment | null;
    endDate: Moment | null;
  }): void => {
    setFocusedInput(START_DATE);

    if (!focusedInput) {
      setStartDate(null);
      setEndDate(null);
    }
    if (focusedInput === START_DATE) {
      setStartDate(dates.startDate);
      setEndDate(null);
    }

    if (focusedInput === END_DATE) {
      setEndDate(dates.endDate);
      setFocusedInput(START_DATE);
    }

    if (dates.startDate && dates.endDate) {
      /**
       * https://mentormate.atlassian.net/browse/NAM-431
       * https://mentormate.atlassian.net/browse/NAM-70?focusedCommentId=167049
       * https://mentormate.atlassian.net/browse/NAM-70?focusedCommentId=166353
       */
      const sDate = dates.startDate.format("MMM DD");
      const eDate = dates.endDate.format("MMM DD");
      const eDateDay = dates.endDate.format("DD");

      const sYear = dates.startDate.format("YYYY");
      const year = dates.endDate.format("YYYY");
      const nYear = sYear !== year ? sYear : false;

      const sameMonth =
        dates.startDate.format("MMM") === dates.endDate.format("MMM");
      let textField = `${sDate}${
        nYear ? ", " + `'${nYear.slice(-2)}` : ""
      } - ${eDate}, ${
        nYear ? `'${year.slice(-2)}` : year
      }` as FocusedInputShape;

      if (sameMonth) {
        textField = `${sDate} - ${eDateDay}, ${year}` as FocusedInputShape;
      }
      setInputDate(textField);
      changeCalendarSelection(true);
    }
  };

  const onFocusChange = (focusedInput: FocusedInputShape | null): void =>
    setFocusedInput(focusedInput);

  useEffect(() => {
    if (endDateState && !focusedInput) {
      setFocusedInput(START_DATE);
    }
  }, [endDateState, focusedInput]);

  return (
    // @ts-expect-error
    <DayPickerRangeController
      {...dateRangePickerConfigNamu}
      orientation="horizontal"
      startDate={startDateState}
      endDate={endDateState}
      onDatesChange={onDatesUpdate}
      focusedInput={focusedInput}
      onFocusChange={onFocusChange}
      // Without initialVisibleMonth we see automatically the selected month first
    />
  );
};

export default HorizontalCalendar;
