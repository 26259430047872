import getConfig from "next/config";

interface IAmplifyConfigurationCookie {
  isHTTPS: boolean;
  domain: string;
  path: string;
  expire: number;
}

export default function AmplifyConfigurationCookie(): IAmplifyConfigurationCookie {
  const { publicRuntimeConfig } = getConfig();
  const webUrl: string = String(publicRuntimeConfig.WEB_URL);
  const domain: string =
    String(process.env.NODE_ENV) === "development"
      ? "localhost"
      : new URL(webUrl).hostname;

  const isHTTPS: boolean =
    String(process.env.NODE_ENV) === "development"
      ? false
      : webUrl.indexOf("https") === 0;
  return {
    isHTTPS,
    domain,
    path: "/",
    expire: 365,
  };
}
