import { FiltersContext } from "providers/FiltersProvider";
import React, { useContext, useCallback } from "react";
import CheckboxCheckedIcon from "@components/pages/package-search/assets/CheckboxCheckedIcon";
import CheckboxUncheckedIcon from "@components/pages/package-search/assets/CheckboxUncheckedIcon";
import { IQueryItemAccommodation } from "@customTypes/filtersProvider";

interface IAccommodationFilterOption {
  item: IQueryItemAccommodation;
}

const AccommodationFilterOption = ({
  item,
}: IAccommodationFilterOption): JSX.Element => {
  const filtersContext = useContext(FiltersContext);

  const renderCheckboxIcon = useCallback(() => {
    if (item.isChecked) {
      return <CheckboxCheckedIcon />;
    } else {
      return <CheckboxUncheckedIcon />;
    }
  }, [item]);

  return (
    <label
      className={`relative flex w-full cursor-pointer flex-row justify-between`}
    >
      <input
        type="checkbox"
        name={item.title}
        className="absolute top-0 left-0 h-0 w-0 opacity-0"
        onChange={(e) =>
          item.isChecked
            ? filtersContext?.removeAccommodation(item)
            : filtersContext?.selectAccommodation(item)
        }
        checked={item.isChecked}
      />
      <span
        className={`flex cursor-pointer items-center text-p13 text-darkblue`}
      >
        {item.title}
      </span>
      {renderCheckboxIcon()}
    </label>
  );
};

export default AccommodationFilterOption;
