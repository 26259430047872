import React, { useContext, useMemo } from "react";
import ImagesRows from "./ImagesRows";
import { FILTER } from "@components/utils/constants";
import AccommodationStep from "./AccommodationStep";
import CalendarStep from "@components/SearchManagement/CalendarStep";
import { FiltersContext } from "providers/FiltersProvider";
import { IUseCalendarReturn } from "@hooks/useCalendar";

interface IStepsBase {
  variant: string;
}

const StepsBase = ({ variant }: IStepsBase): JSX.Element => {
  const filtersContext = useContext(FiltersContext);

  const content = useMemo(() => {
    switch (variant) {
      case FILTER.STEP.ONE:
        return (
          <div className="px-4">
            <ImagesRows variant={variant} />
          </div>
        );
      case FILTER.STEP.TWO:
        return (
          <CalendarStep
            calendarUtils={filtersContext?.calendarUtils as IUseCalendarReturn}
            isPartialFilter={filtersContext?.isPartialFilter}
            tabIndex={1}
          />
        );

      case FILTER.STEP.THREE:
        return (
          <div className="px-4">
            <ImagesRows variant={variant} />
          </div>
        );

      case FILTER.STEP.FOUR:
        return <AccommodationStep />;

      case FILTER.STEP.FIVE:
        return (
          <div className="px-4">
            <ImagesRows variant={variant} />
          </div>
        );
      default:
        return <></>;
    }
  }, [variant, filtersContext?.calendarUtils]);

  return content;
};

export default StepsBase;
