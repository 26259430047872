import React from "react";

const ButtonsWrapper = ({ children }: { children: any }): JSX.Element => {
  let content;

  if (!Array.isArray(children)) {
    children = [children];
  }
  children = children.filter((child: any) => child);

  if (children.length > 1) {
    content = (
      <>
        <div className="w-[30%]">{children[0]}</div>
        <div className="w-[70%]">{children[1]}</div>
      </>
    );
  } else {
    content = children[0];
  }

  return (
    <div className="bottom-0 flex h-[85px] w-full flex-row items-center gap-5 rounded-t-3xl bg-white px-5 desktop:h-fit desktop:w-[350px] desktop:px-0 desktop:pb-8">
      {content}
    </div>
  );
};

export default ButtonsWrapper;
