import React from "react";

const CheckboxCheckedIcon = (): JSX.Element => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="6.71875"
      y="6.20898"
      width="20"
      height="20"
      rx="6"
      fill="#F55B46"
    />
    <line
      x1="12.3934"
      y1="16.3598"
      x2="15.2692"
      y2="19.5338"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="0.75"
      y1="-0.75"
      x2="9.32088"
      y2="-0.75"
      transform="matrix(0.64884 -0.760925 0.802502 0.596649 15.4243 20.5928)"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default CheckboxCheckedIcon;
