import { useState, useEffect } from "react";
import { useDebouncedCallback } from "./debounceHook";

interface IScreenType {
  type: string;
  variant: string;
  size: { height: number; width: number };
}

const WAIT_TIME = 200;

const useScreenType = (): IScreenType => {
  const [screenType, setScreenType] = useState<IScreenType>({
    type: "mobile",
    variant: "",
    size: { height: 0, width: 0 },
  });

  const handleWindowResize = (): void => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setScreenType({
        type: "mobile",
        variant: "real",
        size: { height: window.innerHeight, width: window.innerWidth },
      });
    } else {
      if (window.innerWidth < 1248) {
        setScreenType({
          type: "mobile",
          variant: "",
          size: { height: window.innerHeight, width: window.innerWidth },
        });
      } else if (window.innerWidth < 1320) {
        setScreenType({
          type: "desktop",
          variant: "small",
          size: { height: window.innerHeight, width: window.innerWidth },
        });
      } else if (window.innerWidth < 1440) {
        setScreenType({
          type: "desktop",
          variant: "medium",
          size: { height: window.innerHeight, width: window.innerWidth },
        });
      } else {
        setScreenType({
          type: "desktop",
          variant: "large",
          size: { height: window.innerHeight, width: window.innerWidth },
        });
      }
    }
  };

  useEffect(() => {
    handleWindowResize();
  }, []);

  const debouncedHandleResize = useDebouncedCallback(
    handleWindowResize,
    WAIT_TIME,
    {
      maxWait: WAIT_TIME,
    }
  );

  useEffect(() => {
    window.addEventListener("resize", debouncedHandleResize);
    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, [screenType]);

  return screenType;
};

export default useScreenType;
