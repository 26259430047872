import { useState, useEffect } from "react";
import useSession from "@hooks/useSession";
import { USER_PREF_POST } from "@components/home/routes";
import {
  IAccommodationPreference,
  IActivityPreference,
  ILocationPreference,
  IPackagePreference,
  ISession,
} from "@providers/SessionProvider";
import { StorageLikesType } from "@components/utils/constants";
import { IFilteringEntryResponse } from "../types/modelResponses";

interface IBaseLikeEntry {
  id: number;
}

export interface IUnprocessedLikeEntry extends IBaseLikeEntry {
  title: string | undefined;
}

export interface IProcessedLikeEntry extends IBaseLikeEntry {
  likedAt: string;
}

interface IUseLikeReturn {
  likeHandler: (tag: any) => Promise<void>;
  manageLikes: () => void;
  isLiked: boolean;
  getLikes: () => Promise<{
    localPackages: IProcessedLikeEntry[];
    localAccommodations: IProcessedLikeEntry[];
    localActivities: IProcessedLikeEntry[];
    localLocations: IProcessedLikeEntry[];
  }>;
  syncSessionLikes: (sessionLikes: ISession["userPreferences"]) => void;
  resetLikes: () => void;
}

function localStorageLikes(
  userLikesType: StorageLikesType | undefined,
  session: ISession | null
): IUnprocessedLikeEntry[] | undefined {
  if (session) {
    switch (userLikesType) {
      case "PAC_LIKE":
        return session.userPreferences?.packages?.map((entry) => {
          return { id: entry.id, title: entry.Title };
        });
      case "ACC_LIKES":
        return session.userPreferences?.accommodations?.map((entry) => {
          return { id: entry.id, title: entry.Title };
        });
      case "ACT_LIKES":
        return session.userPreferences?.activities?.map((entry) => {
          return { id: entry.id, title: entry.Title };
        });
      case "LOC_LIKES":
        return session.userPreferences?.locations?.map((entry) => {
          return { id: entry.id, title: entry.Title };
        });
    }
  } else {
    // @ts-expect-error
    return JSON.parse(localStorage.getItem(userLikesType)) || [];
  }
}

async function getLikes(): Promise<{
  localPackages: IProcessedLikeEntry[];
  localAccommodations: IProcessedLikeEntry[];
  localActivities: IProcessedLikeEntry[];
  localLocations: IProcessedLikeEntry[];
}> {
  const moment = (await import("moment")).default;

  const localPackages = localStorage.getItem("PAC_LIKE")
    ? // @ts-expect-error
      JSON.parse(localStorage.getItem("PAC_LIKE")).map(
        (pack: IUnprocessedLikeEntry) => {
          return { id: pack.id, likedAt: moment().format() };
        }
      )
    : [];
  const localAccommodations = localStorage.getItem("ACC_LIKES")
    ? // @ts-expect-error
      JSON.parse(localStorage.getItem("ACC_LIKES")).map(
        (pack: IUnprocessedLikeEntry) => {
          return { id: pack.id, likedAt: moment().format() };
        }
      )
    : [];
  const localActivities = localStorage.getItem("ACT_LIKES")
    ? // @ts-expect-error
      JSON.parse(localStorage.getItem("ACT_LIKES")).map(
        (pack: IUnprocessedLikeEntry) => {
          return { id: pack.id, likedAt: moment().format() };
        }
      )
    : [];
  const localLocations = localStorage.getItem("LOC_LIKES")
    ? // @ts-expect-error
      JSON.parse(localStorage.getItem("LOC_LIKES")).map(
        (pack: IUnprocessedLikeEntry) => {
          return { id: pack.id, likedAt: moment().format() };
        }
      )
    : [];

  return {
    localPackages,
    localAccommodations,
    localActivities,
    localLocations,
  };
}

const useLike = (
  item?: IUnprocessedLikeEntry,
  userLikesType?: StorageLikesType
): IUseLikeReturn => {
  const { session, setSession, isSessionLoading } = useSession();

  const [likes, setLikes] = useState<
    | IPackagePreference[]
    | IAccommodationPreference[]
    | IActivityPreference[]
    | ILocationPreference[]
    | IProcessedLikeEntry[]
    | IUnprocessedLikeEntry[]
    | undefined
  >([]);

  useEffect(() => {
    if (session && !isSessionLoading) {
      switch (userLikesType) {
        case "PAC_LIKE":
          setLikes(session.userPreferences?.packages);
          break;
        case "ACC_LIKES":
          setLikes(session.userPreferences?.accommodations);
          break;
        case "ACT_LIKES":
          setLikes(session.userPreferences?.activities);
          break;
        case "LOC_LIKES":
          setLikes(session.userPreferences?.locations);
          break;
      }
    } else {
      // @ts-expect-error
      setLikes(JSON.parse(localStorage.getItem(userLikesType)) || []);
    }
  }, [session, isSessionLoading]);

  const [change, setChange] = useState<string | undefined>();
  const [isLiked, setIsLiked] = useState(false);

  const manageLikes = (): void => {
    // Listens for localStorage changes to update the state in all windows and modals
    useEffect(() => {
      const handleStorageChange = (): void => {
        setChange("LocalStorage Changed");
        const storedLikes = localStorageLikes(userLikesType, session);
        setLikes(storedLikes);
        if (storedLikes) {
          setIsLiked(storedLikes.some((e) => e.id === item?.id));
        }
      };
      if (!isSessionLoading) {
        window.addEventListener("storage", handleStorageChange);
      }
      return () => window.removeEventListener("storage", handleStorageChange);
    }, [change, session, isSessionLoading]);

    // Checks wheter the infoCard is liked on initial load
    useEffect(() => {
      if (!isSessionLoading) {
        const storedLikes = localStorageLikes(userLikesType, session);
        if (storedLikes) {
          setIsLiked(storedLikes.some((e) => e.id === item?.id));
        }
      }
    }, [change, session, isSessionLoading]);
  };

  const likeHandler = async (tag?: any): Promise<void> => {
    const newLikes = likes?.slice() || [];

    // Strapi logic
    if (session) {
      const likeData = {
        data: {},
      };

      switch (userLikesType) {
        case "PAC_LIKE":
          // @ts-expect-error
          likeData.data.Packages = [];
          // @ts-expect-error
          likeData.data.Packages.push({ id: item.id });
          break;
        case "ACC_LIKES":
          // @ts-expect-error
          likeData.data.Accommodations = [];
          // @ts-expect-error
          likeData.data.Accommodations.push({ id: item.id });
          break;
        case "ACT_LIKES":
          // @ts-expect-error
          likeData.data.Activities = [];
          // @ts-expect-error
          likeData.data.Activities.push({ id: item.id });
          break;
        case "LOC_LIKES":
          // @ts-expect-error
          likeData.data.Locations = [];
          // @ts-expect-error
          likeData.data.Locations.push({ id: item.id });
          break;
      }

      if (
        !tag ||
        (!tag.propertyCategory &&
          (session.userPreferences?.accommodations.some(
            (accom) => accom.id === tag.id
          ) ||
            session.userPreferences?.activities.some(
              (activity) => activity.id === tag.id
            )))
      ) {
        try {
          const updateResponse = await fetch(USER_PREF_POST, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(likeData),
          }).then(async (res) => await res.json());
          switch (userLikesType) {
            case "PAC_LIKE":
              if (
                updateResponse.data.Packages.some(
                  (element: IFilteringEntryResponse) => element.Title
                )
              ) {
                setSession((prevState: any) => {
                  return {
                    ...prevState,
                    userPreferences: {
                      ...prevState.userPreferences,
                      packages: [
                        ...prevState.userPreferences.packages,
                        updateResponse.data.Packages[0],
                      ],
                    },
                  };
                });
                setIsLiked(true);
              } else {
                setSession((prevState: any) => {
                  return {
                    ...prevState,
                    userPreferences: {
                      ...prevState.userPreferences,
                      packages: prevState.userPreferences.packages.filter(
                        (element: IFilteringEntryResponse) =>
                          element.id !== updateResponse.data.Packages[0].id
                      ),
                    },
                  };
                });
                setIsLiked(false);
              }
              break;
            case "ACC_LIKES":
              if (
                updateResponse.data.Accommodations.some(
                  (element: IFilteringEntryResponse) => element.Title
                )
              ) {
                setSession((prevState: any) => {
                  return {
                    ...prevState,
                    userPreferences: {
                      ...prevState.userPreferences,
                      accommodations: [
                        ...prevState.userPreferences.accommodations,
                        updateResponse.data.Accommodations[0],
                      ],
                    },
                  };
                });
                setIsLiked(true);
              } else {
                setSession((prevState: any) => {
                  return {
                    ...prevState,
                    userPreferences: {
                      ...prevState.userPreferences,
                      accommodations:
                        prevState.userPreferences.accommodations.filter(
                          (element: IFilteringEntryResponse) =>
                            element.id !==
                            updateResponse.data.Accommodations[0].id
                        ),
                    },
                  };
                });
                setIsLiked(false);
              }
              break;
            case "ACT_LIKES":
              if (
                updateResponse.data.Activities.some(
                  (element: IFilteringEntryResponse) => element.Title
                )
              ) {
                setSession((prevState: any) => {
                  return {
                    ...prevState,
                    userPreferences: {
                      ...prevState.userPreferences,
                      activities: [
                        ...prevState.userPreferences.activities,
                        updateResponse.data.Activities[0],
                      ],
                    },
                  };
                });
                setIsLiked(true);
              } else {
                setSession((prevState: any) => {
                  return {
                    ...prevState,
                    userPreferences: {
                      ...prevState.userPreferences,
                      activities: prevState.userPreferences.activities.filter(
                        (element: IFilteringEntryResponse) =>
                          element.id !== updateResponse.data.Activities[0].id
                      ),
                    },
                  };
                });
                setIsLiked(false);
              }
              break;
            case "LOC_LIKES":
              if (
                updateResponse.data.Locations.some(
                  (element: IFilteringEntryResponse) => element.Title
                )
              ) {
                setSession((prevState: any) => {
                  return {
                    ...prevState,
                    userPreferences: {
                      ...prevState.userPreferences,
                      locations: [
                        ...prevState.userPreferences.locations,
                        updateResponse.data.Locations[0],
                      ],
                    },
                  };
                });
                setIsLiked(true);
              } else {
                setSession((prevState: any) => {
                  return {
                    ...prevState,
                    userPreferences: {
                      ...prevState.userPreferences,
                      locations: prevState.userPreferences.locations.filter(
                        (element: IFilteringEntryResponse) =>
                          element.id !== updateResponse.data.Locations[0].id
                      ),
                    },
                  };
                });
                setIsLiked(false);
              }
              break;
          }
        } catch (err) {
          console.log(err);
        }
      }
    }

    // Local Storage Logic
    if (!newLikes.some((element) => element.id === item?.id)) {
      newLikes.push(item as any);
    } else {
      const idIndex = newLikes.findIndex((element) => element.id === item?.id);
      if (idIndex !== -1) {
        newLikes.splice(idIndex, 1);
      }
    }

    localStorage.setItem(userLikesType || "", JSON.stringify(newLikes));
    window.dispatchEvent(new Event("storage"));

    if (newLikes.some((element) => element.id === item?.id)) {
      setIsLiked(true);
    } else {
      setIsLiked(false);
    }
  };

  const syncSessionLikes = (
    sessionLikes: ISession["userPreferences"]
  ): void => {
    localStorage.setItem(
      "PAC_LIKE",
      JSON.stringify(
        sessionLikes.packages.map((entry) => {
          return { id: entry.id, title: entry.Title };
        })
      )
    );
    localStorage.setItem(
      "ACC_LIKES",
      JSON.stringify(
        sessionLikes.accommodations.map((entry) => {
          return { id: entry.id, title: entry.Title };
        })
      )
    );
    localStorage.setItem(
      "ACT_LIKES",
      JSON.stringify(
        sessionLikes.activities.map((entry) => {
          return { id: entry.id, title: entry.Title };
        })
      )
    );
    localStorage.setItem(
      "LOC_LIKES",
      JSON.stringify(
        sessionLikes.locations.map((entry) => {
          return { id: entry.id, title: entry.Title };
        })
      )
    );
  };

  const resetLikes = (): void => {
    localStorage.setItem("PAC_LIKE", JSON.stringify([]));
    localStorage.setItem("ACC_LIKES", JSON.stringify([]));
    localStorage.setItem("ACT_LIKES", JSON.stringify([]));
    localStorage.setItem("LOC_LIKES", JSON.stringify([]));
  };

  return {
    likeHandler,
    manageLikes,
    isLiked,
    getLikes,
    syncSessionLikes,
    resetLikes,
  };
};

export { useLike, getLikes };
