export const steps = {
  stepOne: "STEP_ONE",
  stepTwo: "STEP_TWO",
  stepThree: "STEP_THREE",
  success: "SUCCESS",
} as const;

export const TYPE = {
  adult: "ADULT",
  teen: "TEEN",
  children: "CHILDREN",
} as const;

export const COUNT = {
  increment: "INCREMENT",
  decrement: "DECREMENT",
} as const;

export const PAGE_TYPES = {
  Home: 1, // home page
  Packages: 2, // packages
  Location: 3, // location pages
  Accommodations: 4, // accommodation pages
  Activity: 5, // activities page
  LandingPage: 6, // landing page
  Page: 7, // page
  Article: 8, // detailed article
  TravelConsultant: 9, // detailed tc
  Favorites: 10, // Favorites
  ContactPage: 11, // Contact page
  PackageSearch: 12, // Package Search page
  InternalErrorPage: 13, // Page 500
  RedirectingPage: 14, // Blank page with redirect logic
} as const;

type PageTypesKeys = keyof typeof PAGE_TYPES;
export type PageTypes = (typeof PAGE_TYPES)[PageTypesKeys];
