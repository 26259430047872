import React, { useContext } from "react";
import useAppContext from "@hooks/useAppContext";
import { FiltersContext } from "providers/FiltersProvider";
import useScreenType from "@hooks/useScreenType";
import FormModal from "@modules/requestAQuote/FormModal";
import CustomWeekdayHeader from "@components/calendar/CustomWeekdayHeader";
import SearchCalendar from "./SearchCalendar";
import ButtonsWrapper from "@components/common/ButtonsWrapper";
import Button from "@components/common/Button";
import { IUseCalendarReturn } from "@hooks/useCalendar";

interface IMobileCalendarScreenModalProps {
  title: string;
  backHandler: () => void;
  calendarUtils: IUseCalendarReturn;
  secondaryButtonHandler: () => void;
  primaryButtonHandler: (event: React.MouseEvent<HTMLElement>) => void;
}

const ELEMENT_HEIGHTS = {
  header: 44,
  button: 85,
};

const MobileCalendarScreenModal = ({
  title,
  backHandler,
  calendarUtils,
  primaryButtonHandler,
  secondaryButtonHandler,
}: IMobileCalendarScreenModalProps): JSX.Element => {
  const filtersContext = useContext(FiltersContext);
  const appContext = useAppContext();
  const screenType = useScreenType();

  return (
    <FormModal title={title} backModalHandler={backHandler} zIndex={1050}>
      <div
        id="scroll-container-popup"
        className={`flex flex-col overflow-auto bg-blue-5 desktop:mb-0 desktop:h-fit desktop:bg-white desktop:px-[98px] desktop:pb-0`}
        // Dynamic height because of iPhone Safari browser when addressbar is expanded, modal content is hidden behind button);
        style={{
          height: appContext?.isMobile
            ? screenType.size.height - ELEMENT_HEIGHTS.header
            : undefined,
        }}
      >
        <div className="sticky top-0 z-[99] mx-auto w-fit border-b-[1px] border-blue-20 bg-blue-5 text-darkgray">
          <CustomWeekdayHeader />
        </div>
        <SearchCalendar
          setCalendarStatus={calendarUtils.setCalendarStatus}
          startDateState={calendarUtils.startDateState}
          endDateState={calendarUtils.endDateState}
          setStartDate={calendarUtils.setStartDate}
          setEndDate={calendarUtils.setEndDate}
          focusedInput={calendarUtils.focusedInput}
          setFocusedInput={calendarUtils.setFocusedInput}
          monthsToShow={calendarUtils.monthsToShow}
          setMonthsToShow={calendarUtils.setMonthsToShow}
          isMobilePopUp={true}
          observe={"scroll-container-popup"}
        />
      </div>
      <div className="fixed left-0 bottom-0 z-[100] mx-auto flex h-fit w-[calc(100vw)] flex-col items-center desktop:static desktop:my-6 desktop:w-[350px] desktop:bg-transparent">
        <ButtonsWrapper>
          {filtersContext?.calendarUtils.inputDate && (
            <Button
              id={"clear-calendar-btn"}
              variant="no-outline"
              onClick={secondaryButtonHandler}
            >
              Clear&nbsp;Dates
            </Button>
          )}
          <Button
            id={"apply-calendar-btn"}
            variant="primary"
            onClick={primaryButtonHandler}
          >
            Apply
          </Button>
        </ButtonsWrapper>
      </div>
    </FormModal>
  );
};

export default MobileCalendarScreenModal;
