import React from "react";
interface IBackDropDark {
  closeModalHandler?: () => void;
}

const BackDropDark = ({ closeModalHandler }: IBackDropDark): JSX.Element => {
  return (
    <div
      className="fixed top-0 left-0 z-[100] h-full w-full bg-black bg-opacity-50"
      onClick={closeModalHandler}
    />
  );
};

export default BackDropDark;
