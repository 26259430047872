import useAppContext from "@hooks/useAppContext";
import { FiltersContext } from "@providers/FiltersProvider";
import React, { useContext } from "react";

const FindTripButton = ({
  children,
  className,
}: {
  children: string | string[] | JSX.Element | JSX.Element[] | null;
  className?: string;
}): JSX.Element => {
  const appContext = useAppContext();
  const filtersContext = useContext(FiltersContext);

  return (
    <div
      className={className}
      onClick={() => {
        filtersContext?.removeExperience();
        filtersContext?.setGlobalSearch((prevState) => {
          return {
            ...prevState,
            value: true,
          };
        });
        filtersContext?.openFilterModal();
        appContext?.setShowHeaderMenuMobile(false);
      }}
    >
      {children}
    </div>
  );
};

export default FindTripButton;
